import React from "react"
import Layout from "../components/layout"

export default function Home() {
	return (
		<Layout>
			<article>
				<h1>Hello, World!</h1>
				<h2>Welcome to my site.</h2>

				<p>As a Technical Director and an experienced Backend Software Engineer, I am enthusiastic about mentoring and managing the next generation of technologists.</p>

				<p>I have extensive background in software development of complex web, enterprise and cloud applications. I am passionate about coding, constant learning, and building teams who are effective, challenged and appreciated.</p>

				<p>In my free time, I enjoy photography, collecting Flintstones memorabilia, and watching my blind cat chase my seeing cat around the living room.</p>

				<p>This website is a small example of what I do</p>
			</article>
		</Layout>
	)
}
 // 
 // 
 // 
